const links: { [key: string]: string } = {
    "Amadeus IT Group S.A.": "https://www.amadeus.com",
    "Android": "https://www.android.com",
    "Ansible": "https://ansible.com",
    "Ant": "https://ant.apache.org",
    "Atech S/A": "https://www.atech.com.br",
    "Audatex GmbH": "https://www.audatex.com",
    "AWS": "https://aws.amazon.com",
    "Azure": "http://azure.microsoft.com",
    "Backbone": "https://backbonejs.org",
    "Backstage": "https://backstage.io",
    "Celery": "http://www.celeryproject.org",
    "Clarity Design": "https://clarity.design",
    "Concourse": "https://concourse-ci.org",
    "Cypress": "https://cypress.io",
    "D3": "https://d3js.org",
    "Dotdash Inc.": "https://dotdash.com",
    "Django": "https://djangoproject.com",
    "Docker": "https://docker.com",
    "Drools": "https://www.drools.org",
    "EJB": "https://en.wikipedia.org/wiki/Enterprise_JavaBeans",
    "Elasticsearch": "https://elastic.co/products/elasticsearch",
    "European Innovation Academy S.A.": "https://www.inacademy.eu",
    "GCP": "https://cloud.google.com/gcp",
    "Go": "https://golang.org",
    "Gradle": "https://gradle.org",
    "Grafana": "https://grafana.com",
    "Grails": "https://grails.org",
    "GraphQL": "https://graphql.org",
    "Groovy": "https://groovy-lang.org",
    "GWT": "https://www.gwtproject.org",
    "Hibernate": "https://hibernate.org",
    "Hive": "https://hive.apache.org",
    "HP Inc.": "https://www8.hp.com/us/en/home.html",
    "iOS": "https://developer.apple.com/ios",
    "Jaeger": "https://www.jaegertracing.io",
    "Kafka": "https://kafka.apache.org",
    "Keycloak": "https://www.keycloak.org",
    "Kotlin": "https://kotlinlang.org",
    "Kubernetes": "https://kubernetes.io",
    "Java": "https://www.java.com",
    "JavaScript": "https://developer.mozilla.org/en-US/docs/Web/JavaScript",
    "JBoss": "https://wildfly.org",
    "Jenkins": "https://www.jenkins.io",
    "JiBX": "http://jibx.sourceforge.net",
    "JRuby": "https://www.jruby.org",
    "Jython": "https://www.jython.org",
    "Logstash": "https://elastic.co/products/logstash",
    "Marionette": "https://marionettejs.com",
    "Maven": "https://maven.apache.org",
    "Memcached": "https://memcached.org",
    "MongoDB": "https://mongodb.com",
    "MySQL": "https://mysql.com",
    "Node.js": "https://nodejs.org",
    "Nginx": "https://nginx.com",
    "Objective-C": "https://en.wikipedia.org/wiki/Objective-C",
    "OC4J": "https://docs.oracle.com/cd/B14099_19/web.1012/b14011/chap1.htm",
    "OpenMap": "http://openmap-java.org",
    "OpenShift": "https://www.redhat.com/en/technologies/cloud-computing/openshift",
    "Oracle9i": "https://docs.oracle.com/cd/B10501_01/server.920/a96531/ch1_over.htm",
    "Pontifical Catholic University of São Paulo": "http://www.pucsp.br/home",
    "PostgreSQL": "https://postgresql.org",
    "Play": "https://www.playframework.com",
    "Prometheus": "https://prometheus.io",
    "Python": "https://python.org",
    "Quarkus": "https://quarkus.io",
    "Rails": "https://rubyonrails.org",
    "React": "https://reactjs.org",
    "Redis": "https://redis.io",
    "Robot Framework": "https://robotframework.org",
    "Ruby": "https://www.ruby-lang.org",
    "Scala": "https://www.scala-lang.org",
    "Sentry": "https://sentry.io",
    "Spark": "https://spark.apache.org",
    "Spring": "https://spring.io",
    "SQL Server": "https://www.microsoft.com/en-us/sql-server/sql-server-downloads",
    "State University of Campinas": "https://www.unicamp.br/unicamp",
    "Struts": "https://struts.apache.org",
    "Swift": "https://swift.org/documentation",
    "Swing": "https://en.wikipedia.org/wiki/Swing_(Java)",
    "Tanzu": "https://tanzu.vmware.com",
    "Tamino XML Server": "https://en.wikipedia.org/wiki/Software_AG",
    "Tomcat": "http://tomcat.apache.org",
    "Telefonica S.A.": "https://telefonica.com",
    "TypeScript": "https://www.typescriptlang.org",
    "VMware Inc.": "https://vmware.com",
    "Vue": "https://vuejs.org",
    "WebLogic": "https://www.oracle.com/middleware/technologies/weblogic.html",
};

export default links;
