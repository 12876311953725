import { Grid, Link, Typography } from "@material-ui/core";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { Education } from "./data";
import links from "./links";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        bold: {
            fontWeight: 500,
        },
    })
);

interface Props {
    education: Education;
}

export default function EducationView(props: Props) {
    const classes = useStyles(props);
    const { education } = props;

    return (
        <Grid container spacing={1}>
            <Grid item>
                <Typography className={classes.bold}>{education.title}</Typography>
            </Grid>
            <Grid item>
                <Typography className={classes.bold}>{"\u2013"}</Typography>
            </Grid>
            <Grid item>
                <Link href={links[education.institution]} target="_blank" rel="noopener">
                    <Typography className={classes.bold}>{education.institution}</Typography>
                </Link>
            </Grid>
            <Grid item>
                <Typography variant="body1">
                    {education.country}, {education.year}
                </Typography>
            </Grid>
        </Grid>
    );
}
