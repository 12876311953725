import { Divider, Grid, Hidden, Paper, Typography } from "@material-ui/core";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { Resume } from "./data";

import Contact from "./Contact";
import Education from "./Education";
import Experience from "./Experience";
import Personality from "./Personality";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3),
        },
    })
);

interface Props {
    resume: Resume;
}

const DT_STARTED_CAREER = "2003-01-01";
const MS_PER_DAY = 1000 * 60 * 60 * 24;

function getDiffInYears(since: Date, then?: Date) {
    then = then || new Date();
    const utc1 = Date.UTC(since.getFullYear(), since.getMonth(), since.getDate());
    const utc2 = Date.UTC(then.getFullYear(), then.getMonth(), then.getDate());
    const diffInDays = Math.floor((utc2 - utc1) / MS_PER_DAY);
    return Math.ceil(diffInDays / 365);
}

export default function ProfileView(props: Props) {
    const classes = useStyles(props);

    const { resume } = props;

    false &&
        resume.summary.forEach((item, idx) => {
            if (/N\+/.test(item)) {
                const years = getDiffInYears(new Date(DT_STARTED_CAREER));
                resume.summary[idx] = item.replace("N+", `${years}+`);
            }
        });
    return (
        <Paper className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Contact contact={resume.contact} />
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Grid container justify="space-between" spacing={2}>
                        <Grid item lg={7}>
                            {resume.summary.map((item, idx) => (
                                <Typography gutterBottom key={idx} variant="body1" component="li">
                                    {item}
                                </Typography>
                            ))}
                        </Grid>
                        <Hidden only={["xs", "sm", "md"]}>
                            <Grid item lg={5}>
                                <Personality />
                            </Grid>
                        </Hidden>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {resume.experience.map((item, idx) => (
                        <Experience key={idx} experience={item} />
                    ))}
                </Grid>
                <Grid item xs={12} style={{ marginTop: -8 }}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        {resume.education.map((item, idx) => (
                            <Grid item key={idx} xs={12}>
                                <Education education={item} />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
}
