import { Container, CssBaseline } from "@material-ui/core";

import { createMuiTheme, createStyles, makeStyles, responsiveFontSizes } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

import data from "./data.json";
import Profile from "./Profile";
import theme from "./theme.json";

const useStyles = makeStyles((theme) =>
    createStyles({
        printButton: {
            backgroundColor: "whitesmoke",
            margin: theme.spacing(1),
        },
        printContainer: {
            marginBottom: theme.spacing(-2),
            padding: theme.spacing(1, 0, 0, 3),
        },
        root: {
            padding: theme.spacing(3),
        },
    })
);

const App = function (props) {
    const classes = useStyles(props);

    return (
        <ThemeProvider theme={responsiveFontSizes(createMuiTheme(theme))}>
            <CssBaseline />
            <Container className={classes.root} maxWidth="lg">
                <Profile resume={data} />
            </Container>
        </ThemeProvider>
    );
};

export default App;
