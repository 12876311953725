import { Chip, Divider, Grid, Hidden, Link, Typography } from "@material-ui/core";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { format, formatDuration, differenceInMonths } from "date-fns";
import { Experience } from "./data";
import links from "./links";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        bold: {
            fontWeight: 500,
        },
        root: {
            paddingBottom: theme.spacing(2),
        },
    })
);

interface Props {
    experience: Experience;
}

export function parseMonthYear(mmYYYY: string, separator: string = "/") {
    if (mmYYYY.indexOf(separator) === -1) {
        mmYYYY = format(new Date(), "MM/yyyy");
    }
    const [month, year] = mmYYYY.split(separator);
    return new Date(parseInt(year), parseInt(month), 1);
}

export function formatPeriod(period: string[]) {
    const fromDate = parseMonthYear(period[0]);
    const toDate = parseMonthYear(period[1]);
    const periodString = period.join(" \u2013 ");

    const diffInMonths = differenceInMonths(toDate, fromDate) + 1; // Include the last month.
    const years = Math.floor(diffInMonths / 12);
    const months = diffInMonths % 12;
    const duration = formatDuration({ months, years }, { format: ["years", "months"] });
    return { duration, period: periodString };
}

export default function ExperienceView(props: Props) {
    const classes = useStyles(props);
    const { experience } = props;
    const { duration, period } = formatPeriod(experience.period);

    return (
        <Grid container className={classes.root} spacing={1}>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={1}>
                    <Grid item>
                        <Typography className={classes.bold}>{experience.title}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.bold}>{"\u2013"}</Typography>
                    </Grid>
                    <Grid item>
                        <Link href={links[experience.company]} target="_blank" rel="noopener">
                            <Typography className={classes.bold}>{experience.company}</Typography>
                        </Link>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1">{experience.location}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.bold}>•</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1">{period}</Typography>
                    </Grid>
                    {duration && (
                        <>
                            <Grid item>
                                <Typography className={classes.bold}>•</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="body1">{duration}</Typography>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Grid>
            {experience.description.length > 0 && (
                <Grid item xs={12}>
                    {experience.description.map((item, idx) => (
                        <Typography gutterBottom key={idx} variant="body1" component="li">
                            <span className="description" dangerouslySetInnerHTML={{ __html: item }}></span>
                        </Typography>
                    ))}
                </Grid>
            )}
            <Hidden only={["xs"]}>
                <Grid item md={12}>
                    <Grid container spacing={1}>
                        {experience.technologies.map((item, idx) => (
                            <Grid item key={idx}>
                                <Chip
                                    label={item}
                                    size="small"
                                    variant="outlined"
                                    onClick={() => {
                                        const url = links[item];
                                        window.open(url, "_blank");
                                    }}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Hidden>
        </Grid>
    );
}
