import { Avatar, Grid, Hidden, Link, Tooltip, Typography } from "@material-ui/core";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { IconType } from "react-icons";
import { FaFilePdf, FaGithub, FaLinkedin, FaNewspaper, FaStackOverflow, FaThumbtack, FaUser } from "react-icons/fa";

import { Contact } from "./data";
import FlagIcon from "./FlagIcon";

const useStyles = makeStyles((t: Theme) =>
    createStyles({
        avatar: {
            height: 56,
            width: 56,
        },
        chartsContainer: {
            padding: t.spacing(1),
            overflow: "hidden",
        },
        language: {
            cursor: "pointer",
            padding: t.spacing(1),
        },
        name: {
            paddingBottom: t.spacing(0.5),
        },
        personality: {
            height: 21,
            width: 21,
        },
        popover: {
            marginTop: t.spacing(2),
        },
    })
);

const linkIcons: { [key: string]: IconType } = {
    GitHub: FaGithub,
    Instapaper: FaNewspaper,
    LinkedIn: FaLinkedin,
    PDF: FaFilePdf,
    Personality: FaUser,
    Pinboard: FaThumbtack,
    StackOverflow: FaStackOverflow,
};

interface Props {
    contact: Contact;
}

export default function ContactView(props: Props) {
    const classes = useStyles(props);
    const { contact } = props;

    return (
        <Grid container justify="space-between" spacing={2}>
            <Grid item>
                <Grid container spacing={2}>
                    <Hidden only={["xs"]}>
                        <Grid item>
                            <Avatar alt={contact.name} className={classes.avatar} src="/logo.png" />
                        </Grid>
                    </Hidden>
                    <Grid item>
                        <Grid container direction="column">
                            <Grid item>
                                <Typography className={classes.name} variant="h5">
                                    {contact.name}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Grid container spacing={1}>
                                    {Object.entries(contact.links).map(([key, value]) => {
                                        const Icon = linkIcons[key];
                                        return (
                                            <Grid item key={key}>
                                                <Tooltip title={key}>
                                                    <Link
                                                        href={value}
                                                        color="textSecondary"
                                                        variant="body2"
                                                        target="_blank"
                                                        rel="noopener"
                                                    >
                                                        <Icon size="1.5em" />
                                                    </Link>
                                                </Tooltip>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction="column" alignItems="flex-end" spacing={1}>
                    <Grid item>
                        {contact.languages.map((item) => (
                            <Tooltip key={item.code} title={item.level}>
                                <span className={classes.language}>
                                    <FlagIcon code={item.code} size="lg" />
                                </span>
                            </Tooltip>
                        ))}
                    </Grid>
                    <Grid item>
                        <Link href={`mailto:${contact.email}`} variant="body2" target="_blank" rel="noopener">
                            {contact.email}
                        </Link>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
